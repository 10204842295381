import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide22/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide22/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide22/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide22/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide22/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      ¿Cómo construir una comunicación persuasiva al cliente? 8 técnicas que
      debes usar
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      La comunicación persuasiva es una habilidad fundamental para cualquier
      negocio que desee influir en sus clientes y lograr resultados exitosos.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Te presentaremos ocho técnicas imprescindibles para construir una
    comunicación persuasiva al cliente. Aprenderás cómo utilizar el lenguaje
    adecuado, aprovechar la empatía, utilizar pruebas sociales y mucho más.
    <br /> <br />
    Estas estrategias te permitirán captar la atención, generar confianza y
    persuadir a tus clientes de manera efectiva. Ya sea que estés vendiendo un
    producto o presentando un servicio.
  </p>
)

const data = {
  start: {
    support: "Guía | Telemarketing ",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Comprende los intereses y necesidades del cliente.",
      },
      {
        key: 2,
        text: "Establece metas realistas para persuadir al cliente.",
      },
      {
        key: 3,
        text: " Utiliza los datos del cliente para comunicarte.",
      },
      {
        key: 4,
        text: "Convierte las objeciones en oportunidades de venta.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
