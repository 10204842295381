import React from "react"
import TemplatePageGuide  from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide22/dataPageGuide22"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        Cómo construir una comunicación persuasiva al cliente en tu empresa
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/comunicacion-persuasiva-cliente/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Descubre estrategias efectivas para construir una comunicación persuasiva que cautiva a tus clientes y potencia el éxito de tu empresa."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Cómo construir una comunicación persuasiva al cliente en tu empresa"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/comunicacion-persuasiva-cliente/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Descubre estrategias efectivas para construir una comunicación persuasiva que cautiva a tus clientes y potencia el éxito de tu empresa."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Telemarketing "
      location={location}
      typeGuide="guide22"
      clickHereHref="https://beexcc.com/blog/noticias-atencion-al-cliente/"
      data={data}
    />
  </div>
)

export default IndexPage
